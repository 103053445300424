import React from 'react';
import '../../App.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function Contacts() {
  const handleEmailClick = () => {
    // Handle email click - e.g., open email prompt
    window.location.href = 'mailto:romaskukis@gmail.com';
  };

  const handlePhoneClick = () => {
    // Handle phone click - e.g., open phone dialer
    window.location.href = 'tel:+37069826465';
  };

  return (
    <div className='contact_background'>
  
              <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <Card sx={{ minWidth: 225, textAlign: 'left' }}>
                <CardContent>
                  <Typography variant="h4" component="div">
                    <b>Valdybos pirmininkas</b> 
                  </Typography>
                  <Typography variant="body">
                    Romas Kukis
                  </Typography>
                  <Typography variant="body2" onClick={handleEmailClick} style={{ cursor: 'pointer', color: 'blue' }}>
                    romaskukis@gmail.com
                  </Typography>                
                  <Typography variant="body2" onClick={handlePhoneClick} style={{ cursor: 'pointer', color: 'blue' }}>
                  +370 698 26465
                  </Typography>
                  <Typography variant="body2">
                    Pilies 12 g., Nr. 3
                  </Typography>
                  <Typography variant="body2">
                    Kumelionių km., Marijampolės sav.
                  </Typography>
                </CardContent>
              </Card>  
            </ul>
          </div>
             </div>
    </div>
  );
}
