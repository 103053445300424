import React from 'react';
import './Cards.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Cards() {
  return (
    <div className='cards'>
      <h1>Bendrijos naujienos</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>

            <Card sx={{ minWidth: 225, width: 300, textAlign: 'left' }}>
              <CardContent>
                <Typography variant="h4" component="div">
                  Svarbu!!!
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.5, textAlign: 'justify' }}>
                Dėl padidėjusio vandens poreikio žaliųjų plotų laistymui, bendrijoje stinga vandens buitinėms reikmėms.  
                </Typography>
                <Typography variant="body2">
                Todėl visų labai <b>PRAŠOME</b> laistyti pagal namo ar sklypo numerius: 
                </Typography>
                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                  <li>
                    <Typography variant="body" sx={{ lineHeight: 1.5, textAlign: 'justify', marginTop: 1.5 }}>
                    Lyginėmis dienomis laisto lyginiai numeriai (2, 4, 6...) 
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body" sx={{ lineHeight: 1.5, textAlign: 'justify', marginTop: 1.5 }}>
                    Nelyginėmis dienomis – nelyginiai (1, 3, 5 ir t.t.)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" sx={{ lineHeight: 1.5, textAlign: 'justify', marginTop: 1.5, backgroundColor: "#F0A50E" }}>
                    Gyventojams, turintiems vandens telkinius sklypo teritorijoje, <b>GRIEŽTAI DRAUDŽIAMA</b> papildyti juos geriamuoju vandeniu iš sodų bendrijos vandentiekio sistemos.
                    </Typography>
                  </li>
                </ul>
                <Typography variant="body2" sx={{ lineHeight: 1.5, textAlign: 'justify', marginTop: 1.5 }}>
                   Ačiū už supratingumą.
                </Typography>
              </CardContent>       
            </Card>
          </ul>               
        </div>
      </div>
    </div>
  );
}

export default Cards;
