import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Contacts from './components/pages/Contacts';
import Posts from './components/pages/Posts';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/skelbimai' component={Posts} />
          <Route path='/kontaktai' component={Contacts} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
