import React from 'react';
import '../../App.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function Posts() {
  return (
<div className='cards'>
      <h1>Skelbimai</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
    <Card sx={{ minWidth: 225, textAlign: 'left' }}>
    <CardContent>
       <Typography variant="h4" component="div">
         Parduodu
       </Typography>
       <Typography variant="body2">
         Vitas Cislikauskas parduoda padangas.
       </Typography>
     </CardContent>
 </Card>  
 </ul>
         </div>
      </div>
    </div>
  )
}
