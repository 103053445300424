import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <section >
        <div>
             <small class='website-rights'>SB Piliakalnis © 2024</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
